<template>
  <div class="qrcode bg-white">
    <Row class="px-1">
      <i-Col span="24" class="align-justify">
        <Alert show-icon>
          提示
          <template slot="desc">请根据您的需求选择需要生成的页面来生成小程序码.例如需要推广量表，请选择量表界面，并选择需要生成的量表</template>
        </Alert>
        <i-Col span="4" class="align-justify">
          <Form>
            <i-Col span="24" class="align-justify">
              <FormItem label="选择页面" label-position="left">
                <Select class="mr-1" v-model="model11" filterable @on-select="selectProject">
                  <Option
                    v-for="item in pagelist"
                    :value="item.value"
                    :key="item.value"
                  >{{ item.label }}</Option>
                </Select>
              </FormItem>
            </i-Col>
            <i-Col span="24" class="align-justify">
              <FormItem label="页面指定参数值" label-position="left">
                <Select
                  class="mr-1"
                  v-model="model12"
                  filterable
                  clearable
                  @on-clear="clearSelect"
                  @on-select="selectProjectId"
                >
                  <Option
                    v-for="item in projectlist"
                    :value="item.value"
                    :key="item.value"
                  >{{ item.label }}</Option>
                </Select>
              </FormItem>
            </i-Col>
            <i-Col span="24" class="align-justify">
              <FormItem>
                <Button type="primary" @click="handleSubmit">生成</Button>
              </FormItem>
            </i-Col>
          </Form>
        </i-Col>
      </i-Col>
    </Row>
  </div>
</template>
<script>
export default {
  name: "qrcode",
  data() {
    return {
      imgurl: "",
      painting: {
        width: "550px",
        height: "876px",
        background: "#f4f5f7",
        views: [
          {
            type: "image",
            url: require("../assets/logo.png"),
            css: {
              top: "20px",
              left: "36px",
              borderRadius: "40px",
              width: "80px",
              height: "80px",
            },
          },
        ],
      },
      pagelist: [
        {
          value: "/pages/scale/scale",
          label: "量表界面",
        },
        {
          value: "/pages/activity/activity",
          label: "活动界面",
        },
        {
          value: "/pages/consult/consult",
          label: "咨询师界面",
        },
        {
          value: "/pages/index/index",
          label: "普通生成",
        },
      ],
      projectlist: [],
      scaleId: "",
      activityId: "",
      consultId: "",
      model11: "",
      model12: [],
    };
  },
  methods: {
    base64ImgtoFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let suffix = mime.split("/")[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    handleSubmit() {
      this.$get("Wechat/GetWechatQRCode", {
        path: "pages/measurementDetails/measurementDetails",
        query: "?scaleid=6",
      }).then((res) => {
        console.log(res);
        this.imgurl = res.data;
      });
    },
    success(src) {
      this.imgurl = src;
      //转换图片文件
      var imgFile = this.base64ImgtoFile(src);
      console.log(imgFile);
    },
    fail(err) {
      console.log("fail", err);
    },
    selectProject(e) {
      if (e.label == "量表界面") {
        this.getScaleList();
      } else if (e.label == "活动界面") {
        this.getActivityList();
      } else if (e.label == "咨询师界面") {
        this.getConsultList();
      } else {
        this.getQRCode();
      }
    },
    selectProjectId(e) {
      console.log(e.value);
    },
    clearSelect() {},
    getScaleList() {
      this.$get("Scale/GetScaleListBySelect", {
        path:
          "/pages/measurementDetails/measurementDetails?scaleid=" +
          this.scaleId,
      }).then((res) => {
        this.projectlist = res.data.responseList;
      });
    },
    getActivityList() {
      this.$get("Activity/GetActivityListBySelect", {
        path: "/pages/activity/activity?activityid=" + this.activityId,
      }).then((res) => {
        this.projectlist = res.data.responseList;
      });
    },
    getConsultList() {
      this.$get("Admin/GetConsultListBySelect", {
        path:
          "/pages/ConsultationDetails/ConsultationDetails?id=" + this.consultId,
      }).then((res) => {
        this.projectlist = res.data.responseList;
      });
    },
    getQRCode() {
      this.$get("General/GetQRCode", {
        path: "/pages/index/index",
      }).then((res) => {
        this.projectlist = res.data.responseList;
      });
    },
  },
};
</script>